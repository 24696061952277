import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { format, parseISO } from 'date-fns';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';

import './BackupInstructions.css';

import BackupInstructionsStrings from './BackupInstructionsStrings';
import BackupInstructionsService from './BackupInstructionsService';
import DirectoryEditor from './DirectoryEditor';


function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}

const BackupInstructionEditor = ({ data, onUpdated, cloneData }) => {

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const [databases, setDatabases] = useState('');
    const [directories, setDirectories] = useState('');

    const validationSchema = yup.object().shape({
        name: yup.string().required(),
        description: yup.string(),
        enabled: yup.bool(),
        rotation: yup.string().required(),

        s3: yup.object({
            host: yup.string().required(),
            access_key: yup.string().required(),
            secret_key: yup.string().required(),
            bucket_name: yup.string().required(),
            use_ssl: yup.bool()
        }),
    });

    const { register, handleSubmit, setValue, reset, watch, control, unregister, formState: { errors } } = useForm({
        shouldUnregister: true,
        resolver: yupResolver(validationSchema),

        defaultValues: {
            name: "",
            description: "",
            enabled: false,
            s3: {
                host: "",
                access_key: "",
                secret_key: "",
                bucket_name: "",
                use_ssl: true
            },
            databases: [],
            directories: [],
            rotation: "5d2w10m"
        }
    });


    const { fields: dbFields,
        remove: removeDB,
        append: appendDB } = useFieldArray({ control, name: "databases" });

    const { fields: dirFields,
        remove: removeDir,
        append: appendDir } = useFieldArray({ control, name: "directories" });



    useEffect(() => {

        if (data) {
            Object.entries(data).forEach((dataItem) => {
                setValue(dataItem[0], dataItem[1]);
            });
        } else if (cloneData) {
            Object.entries(cloneData).forEach((dataItem) => {
                if (['name', 'creation_date', 'description', 'key'].indexOf(dataItem[0]) == -1) {
                    setValue(dataItem[0], dataItem[1]);
                }
            });
        }

    }, []);


    // [
    //     {
    //         "name": "swcorebot",
    //         "description": "swcorebot",
    //         "creation_date": "2017-07-21T17:32:28Z",
    //         "key": "0lMCBQC12Zu3fCFk6shzVkbmI5Pjho",
    //         "enabled": true,
    //         "databases": [
    //             {
    //                 "db_type": "mongo",
    //                 "host": "hostname",
    //                 "port": 21017,
    //                 "database": "swcorebot",
    //                 "username": "db_user",
    //                 "password": "swcorebot",
    //                 "archive_filename_part": "swcorebot"
    //             }
    //         ],
    //         "directories": [
    //             {
    //                 "path": "/var/www/somebot/",
    //                 "excluded_directories": [
    //                     "*__pycache__"
    //                 ],
    //                 "archive_filename_part": "swcorebot"
    //             }
    //         ],
    //         "s3": {
    //             "host": "swcorebot.ru",
    //             "access_key": "SQblJfGXw42azYRcLu898JyMf5ie8H3t",
    //             "secret_key": "NGnC16Fg/$",
    //             "bucket_name": "swcorebot",
    //             "use_ssl": true
    //         },
    //         "rotation": "3d2w10m"
    //     }
    // ]

    const handleUpdate = (formData) => {

        setMessage("");
        setSuccessMessage("");
        const func = data ? BackupInstructionsService.update : BackupInstructionsService.create;

        func(formData).then((response) => {
            setSuccessMessage("Запись успешно " + (data ? "обновлена" : "создана"));
            setInterval(() => {
                setSuccessMessage("");
            }, 5000);
            onUpdated();
            if (!data || cloneData) {
                navigate("/backup_instructions/");
                reset();
            }
        },
            (error) => {
                setMessage(Helper.errorToString(error));
            });
    };

    const addDatabase = (e) => {
        e.preventDefault();
        appendDB({
            "db_type": "mongo",
            "host": "hostname",
            "port": 21017,
            "database": "",
            "username": "",
            "password": "",
            "archive_filename_part": ""
        });
    };

    const deleteDatabase = (e, index) => {
        e.preventDefault();
        removeDB(index);
    };

    const addDirectory = (e) => {
        e.preventDefault();
        appendDir({
            "path": "/var/www/",
            "excluded_directories": [],
            "archive_filename_part": ""
        });
    };

    const deleteDirectory = (e, index) => {
        e.preventDefault();
        removeDir(index);
    };

    const directoriesRender = !dirFields ? "Директорий пока нет" : dirFields.map((dir, index) => {
        return <DirectoryEditor key={dir.id} control={control} directory={dir} dirIndex={index} unregister={unregister} register={register} deleteDirectory={deleteDirectory} />
    });

    const databasesRender = !dbFields ? "Баз данных пока нет" : dbFields.map((db, index) => {
        const namePrefix = "databases[" + index + "]";
        return <div className='dbitem' key={db.id}>
            <button onClick={(e) => deleteDatabase(e, index)} className='btn trash-btn'><em className="fa fa-trash-o"></em></button>
            <div className='row'>
                <div className='col-lg-6'>
                    <label>Тип базы</label>
                    <select
                        {...register(namePrefix + '[db_type]')}
                        className="form-control"
                    >
                        <option>mongo</option>
                        <option>mysql</option>
                    </select>
                </div>
                <div className='col-lg-6'>
                    <label>Хост</label>
                    <input role="presentation"
                        {...register(namePrefix + '[host]')}
                        className="form-control"
                    />
                </div>
                <div className='col-lg-6'>
                    <label>Порт</label>
                    <input role="presentation"
                        {...register(namePrefix + '[port]')}
                        className="form-control"
                    />
                </div>
                <div className='col-lg-6'>
                    <label>База</label>
                    <input role="presentation"
                        {...register(namePrefix + '[database]')}
                        className="form-control"
                    />
                </div>
                <div className='col-lg-6'>
                    <label>Юзер</label>
                    <input role="presentation"
                        {...register(namePrefix + '[username]')}
                        className="form-control"
                    />
                </div>
                <div className='col-lg-6'>
                    <label>Пароль</label>
                    <input role="presentation"
                        {...register(namePrefix + '[password]')}
                        className="form-control"
                    />
                </div>
                <div className='col-lg-6'>
                    <label>Постфикс архива <span>(необязательно)</span></label>
                    <input role="presentation"
                        {...register(namePrefix + '[archive_filename_part]')}
                        className="form-control"
                    />
                </div>
            </div>
        </div>


        //return <Question key={question.id} question={question} register={register} unregister={unregister} index={index} setValue={setValue} onDelete={(e) => deleteQuestion(e, index)} />
    })

    return (<div>
        {data && <ul className="no-bullets">
            <li>Ключ: {data.key}</li>
            <li>Дата создания: {format(parseISO(data.creation_date), "dd.MM.yyyy")}</li>
        </ul>}

        <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
            <div className='row'>
                <div className=' col-lg-6'>
                    <div className="form-group">

                        <label>Название</label>
                        <input role="presentation"
                            {...register('name')}
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        />
                        <div className="form-error">{errors.name?.message}</div>


                    </div>
                    <div className="form-group">
                        <label>Ротация</label>
                        <input role="presentation"
                            {...register('rotation')}
                            className={`form-control ${errors.rotation ? 'is-invalid' : ''}`}
                        />
                        <div className="form-error">{errors.rotation?.message}</div>
                    </div>
                </div>

                <div className="form-group col-lg-6">
                    <label>Описание <span>(необязательно)</span></label>
                    <textarea rows="4" role="presentation"
                        {...register('description')}
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                    ></textarea>
                    <div className="form-error">{errors.description?.message}</div>
                </div>

            </div>
            <div className="dbitem row">
                <div className='form-group col-lg-6'>
                    <label>S3 Хост</label>
                    <input role="presentation"
                        {...register('[s3][host]')}
                        className={`form-control ${errors.s3 && errors.s3.host ? 'is-invalid' : ''}`}
                    />
                </div>
                <div className='form-group col-lg-6'>
                    <label>S3 Bucket Name</label>
                    <input role="presentation"
                        {...register('[s3][bucket_name]')}
                        className={`form-control ${errors.s3 && errors.s3.bucket_name ? 'is-invalid' : ''}`}
                    />
                </div>
                <div className='form-group col-lg-6'>
                    <label>S3 Ключ доступа</label>
                    <input role="presentation"
                        {...register('[s3][access_key]')}
                        className={`form-control ${errors.s3 && errors.s3.access_key ? 'is-invalid' : ''}`}
                    />
                </div>
                <div className='form-group col-lg-6'>
                    <label>S3 Секретный ключ</label>
                    <input role="presentation"
                        {...register('[s3][secret_key]')}
                        className={`form-control ${errors.s3 && errors.s3.secret_key ? 'is-invalid' : ''}`}
                    />
                </div>

                <div className='form-group col-lg-6'>

                    <input role="presentation" type="checkbox"
                        {...register('[s3][use_ssl]')}
                    />
                    <label>S3 Использовать SSL</label>
                </div>

            </div>
            <div className='mt-4'>
                <div className='d-flex'><h4>Базы данных</h4><button onClick={addDatabase} className='btn btn-secondary ms-3'><em className='fa fa-plus'></em></button></div>
                <div>
                    {databasesRender}
                </div>
            </div>

            <div className='mt-4'>
                <div className='d-flex'><h4>Директории</h4><button onClick={addDirectory} className='btn btn-secondary ms-3'><em className='fa fa-plus'></em></button></div>
                <div>
                    {directoriesRender}
                </div>
            </div>

            <div className="mt-2 d-flex align-items-center justify-content-between">
                <button type="submit" className="btn btn-primary">
                    {data ? "Обновить" : "Создать"}
                </button>
            </div>
        </form >
        {message && (<div className="alert alert-danger mt-4">
            {message}
        </div>)}
        {
            successMessage && (<div className="alert alert-success mt-4">
                {successMessage}
            </div>)
        }
    </div >)
}

export default BackupInstructionEditor