import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import UsersService from "./UsersService";
import Helper from "../common/Helper";

const UserSetPassword = ({ userInfo, onUserUpdated }) => {

    const [message, setMessage] = useState("");

    useEffect(() => {

    }, []);

    // const groupOptions = !groups ? "" : groups.map((group) =>
    //     <option key={group} value={group}>{group}</option>
    // );

    const validationSchema = yup.object().shape({
        password: yup.string()
            .required('Введите пароль')
            .min(6, 'Пароль должен быть больше 6 символов')
            .max(40, 'Пароль не должен превышать 40 символов'),
        confirmPassword: yup.string()
            .required('Повторите пароль')
            .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: userInfo && userInfo.email,
            disabled: userInfo && userInfo.disabled == 1
        }
    });

    const handleUpdate = (data) => {
        //data.groups = data.groups ? (Array.isArray(data.groups) ? data.groups : [data.groups]) : [];

        UsersService.setPassword(data).then(
            (response) => {
                onUserUpdated();
                reset();
                //reset();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );

    };

    return (
        <div className="panel">
            <h2>Изменить пароль</h2>
            <form onSubmit={handleSubmit(handleUpdate)} autoComplete="off">
                <div className="form-group">
                    <label>Пароль</label>
                    <input
                        name="password"
                        type="password"
                        {...register('password')}
                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                        autoComplete="new-password" />
                    <div className="form-error">{errors.password?.message}</div>
                </div>

                <div className="form-group">
                    <label>Повторите пароль</label>
                    <input
                        name="confirmPassword"
                        type="password"
                        {...register('confirmPassword')}
                        className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                        autoComplete="new-password" />
                    <div className="form-error">{errors.confirmPassword?.message}</div>
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <button type="submit" className="btn btn-primary">
                        Обновить
                    </button>
                </div>
            </form>
            {message && (<div className="alert alert-danger mt-4">
                {message}
            </div>)}
        </div>
    );
};

export default UserSetPassword;