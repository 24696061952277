import axios from 'axios';
import AuthService from '../auth/AuthService';
import originHeaders from '../auth/AuthService';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const get = (page = 1, pageSize = 1000) => {
    return axios.get(API_URL + 'backup_instructions/all?page=' + page + '&page_size=' + pageSize, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const getOne = (key) => {
    return axios.get(API_URL + 'backup_instructions?key=' . key, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
}

const create = (data) => {
    return axios.post(API_URL + 'backup_instructions', data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const update = (data) => {
    return axios.post(API_URL + 'backup_instructions/' + data.key, data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const del = (key) => {
    return axios.delete(API_URL + 'backup_instructions/' + key,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}


const on = (key) => {
    return axios.post(API_URL + 'backup_instructions/' + key + '/on', {},
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}


const off = (key) => {
    return axios.post(API_URL + 'backup_instructions/' + key + '/off', {},
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}


const BackupInstructionsService = {
    get,
    getOne,
    update,
    create,
    del,
    on,
    off
}

export default BackupInstructionsService;