import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { format, parseISO, addDays, addMonths, addYears } from 'date-fns';


import Helper from "../common/Helper";

import UsersService from "./UsersService";
import UsersStrings from "./UsersStrings";

import UserSetPassword from "./UserSetPassword";

const UserInfo = ({ userInfo, onUpdated, onDeleted }) => {
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {

    }, []);

    // const groupOptions = !groups ? "" : groups.map((group) =>
    //     <option key={"gr_" + group.id} value={group.id}>{group.name}</option>
    // );

    // let groupsInfo = userInfo.user_groups ? userInfo.user_groups.map((group) => group.name).join(", ") : "";

    const deleteUser = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить пользователя? Пользователь будет полностью удалён из системы.")) return;
        UsersService.deleteUser(id).then(
            (response) => {
                onDeleted();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    }

    return (
        <div className="">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="true">Профиль</button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">

                    <ul className="list-group list-group-flush">
                        {userInfo.email && <li className="list-group-item">{userInfo.email}</li>}
                        {userInfo.api_key && <li className="list-group-item">{userInfo.api_key}</li>}
                        {/* {groupsInfo && <li className="list-group-item">{groupsInfo}</li>} */}
                        <li className="list-group-item">{UsersStrings.status[userInfo.disabled]}</li>
                        {userInfo.creation_date && <li className="list-group-item">Дата регистрации: {format(parseISO(userInfo.creation_date), "dd.MM.yyyy HH:mm")}</li>}
                    </ul>

                    {message && (<div className="alert alert-danger mt-4">
                        {message}
                    </div>)}
                    {successMessage && (<div className="alert alert-success mt-4">
                        {successMessage}
                    </div>)}


                    <UserSetPassword />
                </div>

            </div>
        </div>
    );
};

export default UserInfo;