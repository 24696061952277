import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { format, parseISO } from 'date-fns';

import MainTitle from '../common/MainTitle';
import Modal from '../common/Modal';
import Helper from '../common/Helper';
import Strings from '../common/Strings';
import Toasts from '../common/Toasts';


const DirectoryEditor = ({ directory, dirIndex, unregister, deleteDirectory, register, control }) => {

    const [message, setMessage] = useState('');

    const [extDirs, setExtDirs] = useState();

    const namePrefix = "directories[" + dirIndex + "]";
    const { fields, append, remove } = useFieldArray({
        control,
        name: namePrefix + "[excluded_directories]"
    });

    const addExDir = (e) => {
        e.preventDefault();
        append("");
    };

    const deleteExDir = (e, index) => {
        e.preventDefault();
        remove(index);
    };

    useEffect(() => {

    }, []);

    const extDirsRender = !fields || fields.length == 0 ? "Исключенных директорий нет" : fields.map((field, index) => {
        const nn = namePrefix + "[excluded_directories][" + index + "]";
        return <div className='d-flex exdir' key={field.id}>
            <input {...register(nn)}
                className='form-control'
            />
            <button onClick={(e) => deleteExDir(e, index)} className='btn trash-btn'><em className="fa fa-trash-o"></em></button>
        </div>
    });

    return (<div className='dirItem' key={directory.uuid}>
        <button onClick={(e) => deleteDirectory(e, dirIndex)} className='btn trash-btn'><em className="fa fa-trash-o"></em></button>
        <div className='row'>
            <div className='col-lg-6'>
                <label>Путь</label>
                <input role="presentation"
                    {...register(namePrefix + '[path]')}
                    className="form-control"
                />
            </div>
            <div className='col-lg-6'>
                <label>Постфикс архива <span>(необязательно)</span></label>
                <input role="presentation"
                    {...register(namePrefix + '[archive_filename_part]')}
                    className="form-control"
                />
            </div>
        </div>
        <div className='mt-4'>
            <div className='d-flex'><h5>Исключить директории</h5><button onClick={(e) => addExDir(e)} className='btn btn-secondary ms-3'><em className='fa fa-plus'></em></button></div>
            <div>
                {extDirsRender}
            </div>
        </div>
    </div>)
}

export default DirectoryEditor