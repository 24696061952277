import { Routes, Route, Link, useNavigate, Navigate, NavLink } from "react-router-dom";

import logo from './logo.svg';
import './App.css';

import Home from "./components/Home";
import { useState } from "react";

import RestorePassword from './components/auth/RestorePassword';
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from './components/auth/ForgotPassword';

import AuthService from "./components/auth/AuthService";
import AuthRoute from "./components/auth/AuthRoute";
import NotFoundPage from "./components/NotFoundPage";

import Toasts from "./components/common/Toasts";

import Users from "./components/users/Users";
import BackupInstructions from "./components/backup_instructions/BackupInstructions";

function App() {
    const [menuCollapsed, setMenuCollapsed] = useState(localStorage.getItem("collapsemenu"));
    const [currentAuth, setCurrentAuth] = useState(AuthService.getCurrentAuth());
    const [currentUser, setCurrentUser] = useState(undefined);
    const [message, setMessage] = useState();
    const [showModerationSub, setShowModerationSub] = useState(false);
    const [showPostsSub, setShowPostsSub] = useState(false);

    const logOut = () => { 
        AuthService.logout();
        window.location.reload();
    };

    const toggleMenu = () => {
        let mc = menuCollapsed;
        localStorage.setItem("collapsemenu", !mc);
        
        setMenuCollapsed(!mc);

    };

    return (
        <div>
            <header>

            </header>
            <main>
                <div className={ menuCollapsed ? "main-sildebar collapsed" : "main-sildebar nn"}>
                    <a onClick={toggleMenu} href="#" className="toggle-menu-link d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                        <span className="fs-4">Меню</span>
                        {menuCollapsed && <em className="fa fa-solid fa-bars fs-4"></em>}
                        {!menuCollapsed && <em className="fa fa-solid fa-close fs-4"></em>}
                    </a>
                    <hr />
                    {currentAuth &&
                        <ul className="nav nav-pills flex-column mb-auto">
                            <li className="nav-item">
                                <NavLink to='/' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-home"></em>
                                    <span>Главная</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to='/backup_instructions' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fas fa-archive"></em>
                                    <span>Ресурсы</span>
                                </NavLink>
                            </li>
                            
                            <li>
                                <NavLink to='/users' className={({ isActive, isPending }) =>
                                    isPending ? "pending nav-link text-white" : isActive ? "active nav-link text-white" : "nav-link text-white"}>
                                    <em className="fa fa-fw fa-users"></em>
                                    <span>Пользователи</span>
                                </NavLink>
                            </li>
                        </ul>}

                    {!currentAuth && (<div className="dropdown"><ul className="navbar-nav mb-2 mb-lg-0 d-flex flex-row">

                        <li className="nav-item me-2">
                            <a className="btn btn-outline-primary btn-sm" href="/login">
                                Войти
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/register" className="btn btn-secondary btn-sm">
                                Зарегистрироваться
                            </a>
                        </li>
                    </ul></div>)}
                    <hr />


                    {currentAuth &&
                        <div className="dropdown">
                            <a href="#" className="account-menu-item d-flex align-items-center text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <em className="fa fa-fw fa-user"></em><strong>admin</strong>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                                {/* <li><a className="dropdown-item" href="#">Настройки</a></li>
                                <li><a className="dropdown-item" href="#">Профиль</a></li> */}
                                <li><hr className="dropdown-divider" /></li>
                                <li><a onClick={logOut} className="dropdown-item" href="#"><i className="fa fa-power-off"></i> <span>Выход</span></a></li>
                            </ul>



                            {/* {currentAuth && (<ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
                            {currentUser && (<li className="nav-item me-2"><a href="/balance">Баланс: {currentUser.balance} баллов</a></li>)}
                            <li className="nav-item me-2">
                                <a className="btn btn-outline-primary" href="/personal">
                                    <i className="fa fa-user"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/" className="btn btn-secondary" onClick={logOut}>
                                    <i className="fa fa-power-off"></i>
                                </a>
                            </li>
                        </ul>)}
                        {!currentAuth && (<ul className="navbar-nav mb-2 mb-lg-0">

                            <li className="nav-item me-2">
                                <a className="btn btn-outline-primary btn-sm" href="/login">
                                    Войти
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="/register" className="btn btn-secondary btn-sm">
                                    Зарегистрироваться
                                </a>
                            </li>
                        </ul>)} */}





                        </div>}
                </div>
                <div className="main-content">
                    <Routes>
                        <Route exact path={"/"} element={<AuthRoute><Home /></AuthRoute>} />

                        <Route exact path={"/register"} element={<Register />} />
                        <Route exact path={"/login"} element={<Login setCurrentAuth={setCurrentAuth} />} />
                        <Route exact path={"/restore_pass"} element={<RestorePassword />} />
                        <Route exact path={"/restore-password"} element={<ForgotPassword />} />
                      
                        <Route exact path={"/users"} element={<AuthRoute><Users /></AuthRoute>} />
                        <Route exact path={"/users/:userId"} element={<AuthRoute><Users /></AuthRoute>} />

                        <Route exact path={"/backup_instructions"} element={<AuthRoute><BackupInstructions /></AuthRoute>} />
                        <Route exact path={"/backup_instructions/:backupInstructionKey"} element={<AuthRoute><BackupInstructions /></AuthRoute>} />

                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                    <Toasts message={message} setMessage={setMessage} />
                </div>
            </main>
            <footer>

            </footer>
        </div>
    );
}

export default App;
