import React, { useState, useEffect, useRef } from "react";
import { format, parseISO } from 'date-fns';
import { useNavigate, useParams } from "react-router-dom";

import UserInfo from "./UserInfo";
import UsersService from "./UsersService";

import MainTitle from "../common/MainTitle";
import Modal from "../common/Modal";
import Toasts from '../common/Toasts';

import Helper from "../common/Helper";

import UsersStrings from "./UsersStrings";

import UserEditor from "./UserEditor";

const Users = () => {
    const modalUser = useRef();
    const modalGroup = useRef();
    const navigate = useNavigate();

    const { userId } = useParams();

    const [users, setUsers] = useState("");
    const [groups, setGroups] = useState("");

    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(Helper.defaults.PAGE_SIZE);
    const [showMore, setShowMore] = useState(false);

    const [showNewModal, setShowNewModal] = useState(false);

    const getUsers = () => {
        UsersService.getUsers(1, currentPage * pageSize, searchQuery).then(
            (response) => {

                setShowMore(response.length == pageSize);

                if (userId && !response.find(x => x.id == userId)) {
                    UsersService.getUser(userId).then((userResponse) => {
                        if (userResponse) {
                            //console.log("!!!", userResponse);
                            const all = [];
                            all.push(...response);
                            all.push(userResponse);
                            setUsers(all);
                        }

                    });
                } else {
                    setUsers(response);
                }
                //console.log("USERS", response);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );

    };

    const loadMore = () => {
        const page = currentPage ? currentPage + 1 : 1;
        UsersService.getUsers(page, pageSize, searchQuery).then((response) => {
            const all = [];
            if (users) {
                all.push(...users);
            }
            if (response) {
                response.forEach(element => {
                    if (!all.find((x) => x.id == element.id)) {
                        all.push(element);
                    }
                });

            }
            setUsers(all);
            setCurrentPage(page);

            setShowMore(response.length == pageSize);

        });
    }

    const search = () => {
        const page = 1;
        UsersService.getUsers(page, pageSize, searchQuery).then(
            (response) => {
                setUsers(response);
                setCurrentPage(1);
                setShowMore(response.length == pageSize);

                console.log("USERS", response);
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }

        );
    }

    if (userId) {

    }

    // const getGroups = () => {
    //     UsersService.getGroups().then(
    //         (response) => {
    //             setGroups(response);
    //             console.log("GROUPS", response);
    //         },
    //         (error) => {
    //             setMessage(Helper.errorToString(error));
    //         }
    //     );
    // };

    useEffect(() => {
        getUsers();
        //getGroups();

    }, []);

    const onUserCreated = (data) => {
        modalUser.current.click();

        getUsers();
    };


    const deleteGroup = (e, groupId) => {
        if (!window.confirm("Вы уверены, что хотите удалить группу?")) return;
        // UsersService.deleteGroup(groupId).then(
        //     (response) => {
        //         getGroups();
        //     },
        //     (error) => {
        //         const _content =
        //             (error.response && error.response.data) ||
        //             error.message ||
        //             error.toString();
        //         setError(_content);
        //     }
        // );
    };

    const deleteUser = (e, id) => {
        if (!window.confirm("Вы уверены, что хотите удалить пользователя?")) return;
        UsersService.deleteUser(id).then(
            (response) => {
                refresh();
            },
            (error) => {
                setMessage(Helper.errorToString(error));
            }
        );
    };

    const onUserDeleted = () => {
        refresh();
        navigate("/users");

    }

    const refresh = () => {
        getUsers();

    };

    const userItems = !users ? "" : users.map((user) => {
        let groupsInfo = user.groups ? user.groups.map((group) => group.name).join(", ") : "";

        return <div key={user.id} className="col-xl-3 mb-3">
            <div className="card">
                <div className="card-body">
                    {/* <h5 className="card-title">{user.telegramUsername}</h5> */}
                    <h5 className="card-title">{user.email}</h5>
                    {/* <p className="card-text">Какой-то комментарий? Возможно, био из профиля в телеге?</p> */}
                </div>
                <ul className="list-group list-group-flush">
                    {/* <li className="list-group-item">{user.phone}</li> */}
                    <li className="list-group-item">{user.api_key}</li>
                    {/* {groupsInfo && <li className="list-group-item">{groupsInfo}</li>} */}
                    {user.email && <li className="list-group-item">{user.email}</li>}
                    <li className="list-group-item">{UsersStrings.status[user.disabled]}</li>
                    {user.creation_date && <li className="list-group-item">Дата регистрации: {format(parseISO(user.creation_date), "dd.MM.yyyy HH:mm")}</li>}
                </ul>
                <div className="card-body">
                    <button className="btn btn-secondary" onClick={(e) => navigate("/users/" + user.id)}><i className="fa fa-pencil"></i></button>
                    <button onClick={(e) => deleteUser(e, user.id)} className="btn btn-secondary"><i className="fa fa-trash-o"></i></button>
                </div>
            </div>
            {userId && (user.id == userId) &&
                <Modal onClose={() => navigate("/users")} show={true} title={"Информация о пользователе " + user.email}
                    id={"modalUser" + user.id}>
                    <UserInfo userInfo={user} onUpdated={refresh} onDeleted={onUserDeleted} />
                </Modal>}
        </div>
    });

    // if (userId && users && (users.filter(x => x.id == userId).length == 0)) {
    //     setUsers(users => [{
    //         id: userId,
    //         email: "test" + userId + "@synweb.ru",
    //         phone: "+7 000 000 00 00",
    //         disabled: false,
    //         telegramUsername: "some_username",
    //         creation_date: new Date().toLocaleDateString()
    //     }, ...users]);
    // }

    return (
        <div className="container-fluid">
            <MainTitle title="Пользователи" />
            <div className="control-panel">
                {<button type="button" className="btn btn-primary nowrap" onClick={() => setShowNewModal(true)}>
                    Новый админ
                </button>}
                <div className="input-group search-panel">
                    <input type="text" className="form-control" placeholder="Найти..."
                        aria-label="Найти..." aria-describedby="button-search" onChange={(e) => setSearchQuery(e.target.value)} name="search_query" />
                    <button className="btn btn-outline-secondary" type="button" id="button-search" onClick={search}>Найти</button>
                </div>
            </div>

            <div className="row">
                {userItems}
            </div>
            {showMore &&
                <div className='text-center'><button onClick={loadMore} className='btn btn-success'>Показать еще</button></div>}
            {showNewModal && <Modal show={true} title="Новый пользователь" id="newUserModal" onClose={() => setShowNewModal(false)}>
                <UserEditor onUserUpdated={getUsers} />
            </Modal>}
            <Toasts message={message} setMessage={setMessage} />
        </div>

    );
};

export default Users;