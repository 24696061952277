import axios from "axios";
import AuthService from "../auth/AuthService";
import originHeaders from "../auth/AuthService";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getCurrentUser = () => {
    return axios.get(API_URL + 'users/current', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
    // .catch(function (error) {
    //     console.log(error);
    // })
    // .finally(function () {

    // });

};

const getUsers = (page, page_size, search_query) => {
    return axios.get(API_URL + 'users?page=' + page + "&page_size=" + page_size + (search_query ? ("&search_query=" + search_query) : ""), AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });

};

const getUser = (userId) => {
    return axios.get(API_URL + 'users/' + userId, AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });

};

const getGroups = () => {
    return axios.get(API_URL + 'users/usergroups', AuthService.getTokenHeaders())
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(function () {

        });
}

const createUser = (data) => {
    return axios.post(API_URL + "users", data, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
};

const deleteUser = (userId) => {
    return axios.delete(API_URL + "users/" + userId,
        AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const updateUser = (id, email, user_groups, disabled) => {
    return axios.post(API_URL + "users/" + id, {
        id: id,
        email,
        user_groups: user_groups,
        disabled: disabled
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const setPassword = (id, password) => {
    return axios.post(API_URL + "users/" + id + "/set_password", {
        password
    }, AuthService.getTokenHeaders())
        .then((response) => {
            return response.data;
        });
}

const resetPassword = (email) => {
    return axios.post(API_URL + "users/reset_password", {
        email
    }, originHeaders)
        .then((response) => {
            return response.data;
        });
}

const proofResetPassword = (data) => {
    return axios.post(API_URL + "users/proof_reset_password", data, originHeaders)
        .then((response) => {
            return response.data;
        });
}

const UsersService = {
    getUser,
    getUsers,

    createUser,

    resetPassword,
    deleteUser,
    getCurrentUser,

    updateUser,
    proofResetPassword,

    setPassword
}

export default UsersService;